<template>
    <b-modal
        id="alertmodal"
        v-model="displayModal"
        hide-footer
        hide-header
        body-class="p-0"
    >
        <div class="modal-content">
            <div class="modal-header">
                <button
                    @click="hideModal"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="verification_block">
                    <p>Please check the terms and conditions checkbox</p>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        modalShow: {
            default: false,
        },
    },
    data() {
        return {
            displayModal: false,
        }
    },
    created() {
        this.displayModal = this.modalShow
    },
    methods: {
        hideModal() {
            this.displayModal = false
        },
    },
}
</script>
