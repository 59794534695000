<template>
    <div class="topbottom_padding">
        <b-modal
            ref="my-modal"
            hide-footer
            hide-header
            no-close-on-esc
            no-close-on-backdrop
        >
            <div class="d-block text-center">
                <div class="whiteimput">
                    <div class="innerblock">
                        <div class="form-group">
                            <b-form-input
                                type="text"
                                name="occupation"
                                placeholder="Please specify"
                                v-model="occupation_name"
                                autocomplete="off"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <b-button
                class="mt-3"
                variant="outline-danger"
                block
                @click="hideModal"
                >Submit</b-button
            >
        </b-modal>

        <div class="container">
            <h1>Create Your Account</h1>
            <!-- <div class="white_block" style="margin-bottom: 1rem">
                <div class="information-item">
                    <h3>Before we start:</h3>
                    <p>
                        We require your identity to be verified before we can
                        approve your account and provide complete access to the
                        Grand Casino web app. This process can also be completed
                        on the mobile version. To download go to Apple Store or
                        Google Play Store.
                    </p>
                </div>
                <div class="information-item">
                    <h3>You will need:</h3>
                    <ul class="list-group">
                        <li>A device (laptop/desktop) with webcam access</li>
                        <li>
                            New Zealand Driver License (or) New Zealand Passport
                        </li>
                    </ul>
                </div>
                <div class="information-item">
                    <ul class="list-group">
                        <h3>Total time:</h3>
                        <li>Approx. 5 minutes</li>
                    </ul>
                </div>
            </div> -->
            <form @submit.stop.prevent="onSubmit" ref="signUpForm">
                <!--Block-->
                <div class="block">
                    <div class="whiteimput">
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    v-model="$v.form.email.$model"
                                    :state="validateState('email')"
                                    aria-describedby="emailHelp"
                                    :class="{
                                        email_check: validateState('email'),
                                    }"
                                    autocomplete="off"
                                ></b-form-input>
                                <b-form-invalid-feedback id="emailHelp"
                                    >Please Enter a valid
                                    email</b-form-invalid-feedback
                                >
                            </div>
                            <div class="form-group">
                                <div class="inner_block">
                                    <div class="prefix_holder">
                                        <img
                                            src="@/assets/images/flag/nz.jpg"
                                            alt=""
                                        />
                                        <span>+64</span>
                                    </div>
                                    <b-form-input
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="Mobile Number"
                                        v-model="$v.form.phoneNumber.$model"
                                        :state="validateState('phoneNumber')"
                                        aria-describedby="phoneNumberHelp"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        id="phoneNumberHelp"
                                        >Please Enter a valid mobile
                                        number</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    :type="showPassword ? 'text' : 'password'"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    v-model="$v.form.password.$model"
                                    :state="validateState('password')"
                                    aria-describedby="passwordHelp"
                                    autocomplete="off"
                                ></b-form-input>
                                <b-form-invalid-feedback id="passwordHelp"
                                    >Password is
                                    required</b-form-invalid-feedback
                                >
                                <a
                                    @click="showPassword = !showPassword"
                                    class="forgot_psw"
                                    >{{ showPassword ? 'Hide' : 'Show' }}</a
                                >
                            </div>
                            <div class="form-group">
                                <b-form-input
                                    :type="
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    "
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    v-model="$v.form.confirmPassword.$model"
                                    :state="validateState('confirmPassword')"
                                    aria-describedby="confirmPasswordHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback
                                    id="confirmPasswordHelp"
                                    >Password did not
                                    match</b-form-invalid-feedback
                                >
                                <a
                                    @click="
                                        showConfirmPassword =
                                            !showConfirmPassword
                                    "
                                    class="forgot_psw"
                                    >{{
                                        showConfirmPassword ? 'Hide' : 'Show'
                                    }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <p class="password_text">Passwords must be a minimum of 8 characters and include at least 1 uppercase, 1 lowercase and 1 number.</p>
                </div>
                <!--End Block -->

                <!--Block-->
                <div class="block">
                    <h2>Personal Details</h2>
                    <div class="whiteimput">
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    v-model="$v.form.firstName.$model"
                                    :state="validateState('firstName')"
                                    aria-describedby="firstNameHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback id="firstNameHelp"
                                    >First name is
                                    required</b-form-invalid-feedback
                                >
                            </div>
                            <div class="form-group">
                                <b-form-input
                                    id="middlename"
                                    name="middlename"
                                    placeholder="Middle Name"
                                    v-model="form.middlename"
                                    aria-describedby="middlenameHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback id="middlenameHelp"
                                    >Middle name is
                                    required</b-form-invalid-feedback
                                >
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    v-model="$v.form.lastName.$model"
                                    :state="validateState('lastName')"
                                    aria-describedby="lastNameHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback id="lastNameHelp"
                                    >Last name is
                                    required</b-form-invalid-feedback
                                >
                            </div>
                            <div class="form-group">
                                <b-form-input
                                    id="example-input"
                                    v-mask="'##/##/####'"
                                    v-model="
                                        $v.form.calInput.$model
                                    "
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    autocomplete="off"
                                    aria-describedby="calInputHelp"
                                    :state="
                                        validateState('calInput')
                                    "
                                ></b-form-input>
                                <b-form-invalid-feedback
                                    id="dobHelp"
                                    >You must be older than 20
                                    years</b-form-invalid-feedback
                                >
                                <div
                                    class="dropdown_select_calender"
                                    style="
                                        height: auto;
                                        position: absolute;
                                        top: 22px;
                                        right: 0;
                                    "
                                >
                                    <b-form-datepicker
                                        show-decade-nav
                                        :max="dobConstraint"
                                        button-only
                                        right
                                        locale="en"
                                        class="form-control"
                                        placeholder="Date Of Birth"
                                        :date-format-options="{ day: '2-digit',  month: '2-digit', year: 'numeric' }"
                                        @context="onContext"
                                    />
                                </div>
                                <!-- <div
                                    class="dropdown_select_calender"
                                >
                                    <b-form-datepicker
                                        show-decade-nav
                                        v-model="$v.form.dob.$model"
                                        :max="dobConstraint"
                                        locale="en"
                                        :state="
                                            validateState('dob')
                                        "
                                        aria-describedby="dobHelp"
                                        class="form-control"
                                        placeholder="Date Of Birth"
                                    />
                                    <b-form-invalid-feedback
                                        id="dobHelp"
                                        >You must be older than 20
                                        years</b-form-invalid-feedback
                                    >
                                </div> -->
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group spl-padding">
                                <div class="dropdown_select">
                                    <b-form-select
                                        v-model="$v.form.gender.$model"
                                        placeholder="Select Gender"
                                        :options="genders"
                                        class="form-control"
                                        value-field="gender_id"
                                        text-field="gender_name"
                                        :state="validateState('gender')"
                                        aria-describedby="genderHelp"
                                    ></b-form-select>
                                    <b-form-invalid-feedback id="genderHelp" 
                                        >Please choose a
                                        gender</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="dropdown_select">
                                    <b-form-select
                                        v-model="$v.form.occupation.$model"
                                        placeholder="Select Occupation"
                                        :options="occupation_arr"
                                        class="form-control"
                                        value-field="id"
                                        text-field="occupation_name"
                                        :state="validateState('occupation')"
                                        aria-describedby="occupationHelp"
                                        @change="occupationSelected"
                                    />
                                    <b-form-invalid-feedback id="occupationHelp"
                                        >Occupation is
                                        required</b-form-invalid-feedback
                                    >
                                </div>
                                <div
                                        class="selected-occupation"
                                        v-if="form.occupation === 9"
                                    >
                                        Selected Occupation:
                                        {{ occupation_name }}
                                </div>
                            </div>
                        </div>
                        <div class="innerblock1 question-holder">
                            <div class="inner-question-holder form-group" style="width: 80%;">
                                    Is your spouse or partner a member?
                            </div>
                            <div class="form-group custom-control-radio" style="text-align: right; width: 20%;">
                                    <b-form-radio-group
                                        id="radio-group-1"
                                        size="lg"
                                        v-model="hasSpouseOrPartner"
                                        :options="[
                                            {
                                                text: 'Yes',
                                                value: true,
                                            },
                                            {
                                                text: 'No',
                                                value: false,
                                            },
                                        ]"
                                        name="hasSpouseOrPartner"
                                    />
                            </div>
                        </div>
                        <div class="innerblock1 question-holder">
                            <div class="inner-question-holder form-group" style="width: 80%;">
                                Have you ever been excluded from a
                                Casino?
                            </div>
                            <div class="form-group custom-control-radio" style="text-align: right; width: 20%;">
                                <b-form-radio-group
                                    id="radio-group-2"
                                    size="lg"
                                    v-model="isExcludedFromCasino"
                                    :options="[
                                        {
                                            text: 'Yes',
                                            value: true,
                                        },
                                        {
                                            text: 'No',
                                            value: false,
                                        },
                                    ]"
                                    name="isExcludedFromCasino"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Block -->

                <!--Block-->
                <div class="block">
                    <h2>Address Details</h2>
                    <div class="whiteimput">
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    id="address"
                                    name="address"
                                    placeholder="Address"
                                    v-model="$v.form.address.$model"
                                    :state="validateState('address')"
                                    aria-describedby="addressHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback id="addressHelp"
                                    >Address is
                                    required</b-form-invalid-feedback
                                >
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    id="suburb"
                                    name="suburb"
                                    placeholder="Suburb"
                                    v-model="form.suburb"
                                    aria-describedby="suburbHelp"
                                    ref="suburb"
                                ></b-form-input>
                                
                            </div>
                            <div class="form-group spl-padding">
                                <div class="dropdown_select">
                                    <b-form-select
                                        v-model="$v.form.city.$model"
                                        placeholder="Select City"
                                        :options="cities"
                                        class="form-control"
                                        value-field="city_id"
                                        text-field="city_name"
                                        :state="validateState('city')"
                                        aria-describedby="cityHelp"
                                    ></b-form-select>
                                    <b-form-invalid-feedback id="cityHelp"
                                        >Please choose a
                                        city</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group">
                                <b-form-input
                                    id="postCode"
                                    name="postCode"
                                    placeholder="Post Code"
                                    v-model="$v.form.postCode.$model"
                                    :state="validateState('postCode')"
                                    aria-describedby="postCodeHelp"
                                ></b-form-input>
                                <b-form-invalid-feedback id="postCodeHelp"
                                    >Post Code is
                                    required</b-form-invalid-feedback
                                >
                            </div>
                            <div class="form-group">
                                <div class="dropdown_select">
                                    <b-form-select
                                        v-model="$v.form.region.$model"
                                        placeholder="Select Region"
                                        :options="regions"
                                        class="form-control"
                                        value-field="region_id"
                                        text-field="region_name"
                                        :state="validateState('region')"
                                        aria-describedby="regionHelp"
                                    ></b-form-select>
                                    <b-form-invalid-feedback id="regionHelp"
                                        >Please choose a
                                        region</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="innerblock">
                            <div class="form-group">
                                <div class="dropdown_select">
                                    <b-form-select
                                        v-model="
                                            $v.form.citizenship_country_id
                                                .$model
                                        "
                                        placeholder="Select Country of Citizenship"
                                        :options="countries"
                                        class="form-control"
                                        value-field="id"
                                        text-field="country_name"
                                        :state="
                                            validateState(
                                                'citizenship_country_id'
                                            )
                                        "
                                        aria-describedby="countryHelp"
                                    ></b-form-select>
                                    <b-form-invalid-feedback id="countryHelp"
                                        >Please choose your country of
                                        citizenship</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Block -->
                <div class="block inner_pre" v-if="currentIndex < steps.length">
                    <b-card
                        header-tag="header"
                        footer-tag="footer"
                        v-if="signupQuestions.length > 0"
                    >
                        <template #header>
                            <h3 class="mb-2">
                                {{ steps[currentIndex].section_title }}
                            </h3>
                        </template>
                        <b-card-text>
                            <b-container class="mb-4">
                                <p class="question">
                                    {{ steps[currentIndex].question }}
                                </p>
                            </b-container>
                            <b-container class="mb-4" />
                            <b-container>
                                <div
                                    :key="key"
                                    v-for="(option, key) in steps[currentIndex]
                                        .options"
                                    class="flex mb-2 pblock"
                                >
                                    <b-avatar
                                        :src="option.icon"
                                        size="3rem"
                                        class="mr-4"
                                    />
                                    <span class="option-text mr-4">{{
                                        option.option_text
                                    }}</span>
                                    <div class="round">
                                        <input
                                            type="checkbox"
                                            :id="`checkbox-${option.id}`"
                                            v-model="option.member_ans"
                                        />
                                        <label
                                            :for="`checkbox-${option.id}`"
                                        ></label>
                                    </div>
                                </div>
                            </b-container>
                        </b-card-text>
                        <template #footer>
                            <b-form-invalid-feedback
                                id="prefQuesHelp"
                                style="display: block"
                                v-show="prefErr"
                                >Please select at least one
                                option</b-form-invalid-feedback
                            >
                            <div class="box-footer">
                                <a
                                    class="btn footer-btn prv"
                                    v-if="currentIndex > 0"
                                    @click.prevent="onPrev"
                                    >Previous</a
                                >
                                <a
                                    class="btn footer-btn"
                                    @click.prevent="onNext"
                                    style="margin-left: auto; padding-right: 30px;"
                                    >{{ nextButtonText }}</a
                                >
                            </div>
                        </template>
                    </b-card>
                </div>
                <div class="block" v-else>
                    <b-card>
                        <b-card-text
                            >Thank you for answering these questions. It will
                            help us to know your preference</b-card-text
                        >
                    </b-card>
                </div>

                <div class="block">
                    <div class="lastblock">
                        <div class="stack">
                            <div class="form-group form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    v-model="termsCheck"
                                />
                                <a href="#" @click="openTnC"
                                    >Terms and conditions</a
                                >
                            </div>
                            <div class="form-group form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="exampleCheck1"
                                    v-model="is_consent_given_for_verification"
                                />
                                <a href="#">{{ consent_text }}</a>
                            </div>
                        </div>
                        <a @click="onSubmit" class="btn">SIGNUP</a>
                    </div>
                </div>
            </form>
        </div>
        <alertmodal :modalShow="alertModalShow" />
    </div>
</template>

<script>
import {
    BFormInvalidFeedback,
    BFormInput,
    BFormDatepicker,
    BCard,
    BCardText,
    BContainer,
    BFormRadioGroup
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import { required, email, numeric, sameAs } from 'vuelidate/lib/validators'
import alertmodal from '@/components/alert_modal'
import moment from 'moment'

export default {
    loadAFWIdget: true,
    mixins: [validationMixin],
    components: {
        BFormInvalidFeedback,
        BFormInput,
        BFormDatepicker,
        alertmodal,
        BCard,
        BCardText,
        BContainer,
        BFormRadioGroup
    },
    data() {
        return {
            urls: [],
            countries: [],
            cities: [],
            regions: [],
            genders: [],
            membershipLevels: [],
            termsCheck: false,
            showPassword: false,
            showConfirmPassword: false,
            dobConstraint: new Date(),
            alertModalShow: false,
            consent_text: '',
            is_consent_given_for_verification: false,
            occupation_arr: [],
            occupation_name: '',
            hasSpouseOrPartner: null,
            isExcludedFromCasino: null,
            signupQuestions: [],
            steps: [],
            currentIndex: 0,
            signupQuestionAns: [],
            prefErr: false,
            form: {
                email: '',
                password: '',
                confirmPassword: '',
                phoneNumber: '',
                firstName: '',
                middlename: '',
                lastName: '',
                occupation: 0,
                gender: 0,
                address: '',
                suburb: '',
                city: 0,
                postCode: null,
                region: 0,
                citizenship_country_id: 0,
                calInput: '',
            },
            widget: null,
            calInput: ''
        }
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password'),
            },
            phoneNumber: {
                required,
            },
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            occupation: {
                required,
                minValue: (value) => {
                    console.log('occ', value > 0)
                    return value > 0
                },
            },
            calInput: {
                minValue: (value) => {
                    return (
                        moment(value, 'DD/MM/YYYY') <
                        moment().subtract(20, 'years')
                    )
                },
            },
            gender: {
                required,
                minValue: (value) => {
                    return value > 0 ? true : false
                },
            },
            address: {
                required,
            },
            city: {
                required,
                minValue: (value) => {
                    return value > 0 ? true : false
                },
            },
            postCode: {
                required,
                numeric,
            },
            region: {
                required,
                minValue: (value) => {
                    return value > 0 ? true : false
                },
            },
            citizenship_country_id: {
                required,
                minValue: (value) => {
                    return value > 0 ? true : false
                },
            },
        },
    },
    created() {
        this.dobConstraint.setYear(new Date().getFullYear() - 20)

        this.fetchConfig()
    },
    mounted() {
        this.placeApi()
    },
    computed: {
        showNextButton() {
            return (
                this.steps.length > 0 &&
                this.steps[this.currentIndex].options.reduce((op, option) => {
                    return op || option.next_question_id > 0
                }, false)
            )
        },
        nextButtonText() {
            return this.currentIndex > 0 ? 'NEXT' : 'SAVE PREFERENCES'
        },
    },
    methods: {
        onContext(ctx) {
            if ('selectedDate' in ctx && ctx.selectedDate) {
                this.$v.form.calInput.$model = ctx.selectedYMD
                    ? this.moment(String(ctx.selectedYMD)).format('DD/MM/YYYY')
                    : ''
            }
        },
        onPrev() {
            if (this.currentIndex - 1 > 0) {
                this.currentIndex--
            } else {
                this.currentIndex = 0
            }
            this.prefErr = false
        },
        onNext() {
            let curr_ques_id = this.steps[this.currentIndex].question_id
            let error = true
            this.steps[this.currentIndex].options.forEach((option) => {
                if (option.member_ans) {
                    this.signupQuestionAns.push({
                        pref_option_id: option.id,
                        member_id: 0,
                        pref_question_id: curr_ques_id,
                        member_ans: true,
                    })
                    error = false
                }

                if (option.member_ans && option.next_question_id > 0) {
                    let next_question = this.signupQuestions.find(
                        (item) => item.question_id === option.next_question_id
                    )
                    if (next_question) {
                        this.steps.push(next_question)
                    }
                }
            })
            if (!error) {
                this.currentIndex++
            }
            this.prefErr = error
        },

        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                console.log(resp.data)
                this.urls = resp.data.urls
                this.consent_text = resp.data.consent_text
                this.countries = [
                    {
                        id: 0,
                        country_name: 'Select Country of Citizenship',
                        disabled: true,
                    },
                    ...resp.data.country_list,
                ]
                // this.cities = resp.data.city_list
                this.cities = [
                    { city_id: 0, city_name: 'Select City', disabled: true },
                    ...resp.data.city_list,
                ]
                // this.regions = resp.data.region_list
                this.regions = [
                    {
                        region_id: 0,
                        region_name: 'Select Region',
                        disabled: true,
                    },
                    ...resp.data.region_list,
                ]
                // this.genders = resp.data.gender_list
                this.genders = [
                    {
                        gender_id: 0,
                        gender_name: 'Select Gender',
                        disabled: true,
                    },
                    ...resp.data.gender_list,
                ]
                // console.log(this.genders)
                this.occupation_arr = [
                    {
                        id: 0,
                        occupation_name: 'Select Occupation',
                        disabled: true,
                    },
                    ...resp.data.occupations,
                    {
                        id: 9,
                        occupation_name: 'Other',
                    },
                ]
                this.membershipLevels = resp.data.membership_level_list
                this.signupQuestions = resp.data.signup_questions
                this.signupQuestions.map((item) => {
                    item.member_ans = false
                    return item
                })
                this.steps.push(this.signupQuestions[0])
            } catch (e) {
                console.error(e)
                this.$bvToast.toast('Oops! Something went wrong', {
                    title: `Error`,
                    variant: `danger`,
                    solid: true,
                })
            }
        },
        validateState(name) {
            return this.$helper.validateState(name, this)
        },
        showModal() {
            this.$bvModal.show('alertmodal')
        },
        openTnC() {
            window.open(this.urls.terms_conditions_url, '_blank')
        },
        occupationSelected(item) {
            if (item === 9) {
                this.occupation_name = ''
                this.$refs['my-modal'].show()
            } else {
                this.occupation_name = this.occupation_arr.find(
                    (occ) => occ.id === item
                ).occupation_name
            }
        },
        hideModal() {
            if (this.occupation_name.trim().length === 0) {
                this.form.occupation = 0
            }
            this.$refs['my-modal'].hide()
        },
        onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                console.log('err', this.$v.form.$anyError)
                console.log('errors horrors ', this.$v.form.$errors)
                console.log('errors horrors ', this.$v.$errors)
                console.log('errors horrors ', this.$v.form.$error)
                console.log('errors horrors ', this.$v)
                return
            }
            if (this.hasSpouseOrPartner === null) {
                this.$helper.showMessage(
                    'Please answer if your spouse or partner a member or not',
                    'danger'
                )
                return
            }
            if (this.isExcludedFromCasino === null) {
                this.$helper.showMessage(
                    'Please answer have you ever been excluded from a casino or not',
                    'danger'
                )
                return
            }
            if (!this.termsCheck) {
                this.$helper.showMessage(
                    'Please check the terms and conditions checkbox',
                    'danger'
                )
                return
            }
            if (this.signupQuestionAns.length === 0) {
                this.$helper.showMessage(
                    'Please select at least one option in Preferences and save',
                    'danger'
                )
                return
            }
            const dateExploded = this.form.calInput
                .split('/')
                .reverse()
                .join('-')
            this.$store
                .dispatch('auth/signup', {
                    first_name: this.form.firstName,
                    middle_name: this.form.middlename,
                    last_name: this.form.lastName,
                    occupation: this.occupation_name,
                    email: this.form.email,
                    password: this.form.password,
                    phone_number: this.form.phoneNumber,
                    country_code: 64,
                    dob: dateExploded,
                    suburb: this.form.suburb,
                    gender_id: this.form.gender,
                    street_address: this.form.address,
                    postal_code: this.form.postCode,
                    region_id: this.form.region,
                    city_id: this.form.city,
                    citizenship_country_id: this.form.citizenship_country_id,
                    is_consent_given_for_verification: this
                        .is_consent_given_for_verification
                        ? 1
                        : 0,
                    has_spouse_or_partner: this.hasSpouseOrPartner ? 1 : 0,
                    is_excluded_from_a_casino: this.isExcludedFromCasino
                        ? 1
                        : 0,
                    type: 'W',
                    token: `WEBAPP-${this.moment().unix()}`,
                    signup_ques_ans: this.signupQuestionAns,
                })
                .then((res) => {
                    // this.$helper.hideLoading();
                    if (res && res.status === false) {
                        console.log('logged in')
                    }
                })
                .catch((err) => {
                    console.error('login error', err)
                })
        },

        placeApi() {
            let script = document.createElement('script')
            let vm = this
            script.setAttribute(
                'src',
                'https://api.addressfinder.io/assets/v3/widget.js'
            )
            script.setAttribute('async', true)
            script.onload = () => {
                this.widget = new window.AddressFinder.Widget(
                    document.getElementById('address'),
                    'FUHT89L634EGXQJVPMNA',
                    'NZ',
                    {
                        address_params: {},
                        empty_content:
                            'No address found. This could be a new address, or you may need to check the spelling',
                        show_locations: true,
                    }
                )
                this.widget.on(
                    'result:select',
                    function (fullAddress, metaData) {
                        const selected =
                            new window.AddressFinder.NZSelectedAddress(
                                fullAddress,
                                metaData
                            )
                        const add_obj = {
                            address:
                                selected.address_line_1() +
                                (selected.address_line_2()
                                    ? ', ' + selected.address_line_2()
                                    : ''),
                            suburb: selected.suburb() || '',
                            city: selected.city() || '',
                            postcode: selected.postcode() || '',
                            region: metaData.region,
                        }

                        console.log(metaData.region);

                        const select_city = vm.cities.find((obj) => {
                            return (
                                obj.city_name.toLowerCase() ===
                                add_obj.city.toLowerCase()
                            )
                        })

                        vm.form.city = select_city ? select_city.city_id : 0

                        const select_region = vm.regions.find((obj) => {
                            return (
                                obj.region_name.toLowerCase() ===
                                add_obj.region
                                    .toLowerCase()
                            )
                        })
                        vm.form.region = select_region
                            ? select_region.region_id
                            : 0

                        vm.form.address = add_obj.address
                        document.getElementById('address').value =
                            add_obj.address
                        vm.form.suburb = add_obj.suburb
                        vm.form.postCode = add_obj.postcode
                    }
                )
            }
            document.head.appendChild(script)
        },
    },
}
</script>

<style>
p {
    font-size: 1.4rem;
}
.box-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer-btn {
    max-width: 25rem !important;
}
.option-text {
    min-width: 25rem;
    font-size: 1.6rem !important;
}
.flex {
    display: flex;
}

.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type='checkbox'] {
    visibility: hidden;
}

.round input[type='checkbox']:checked + label {
    background-color: var(--golden);
    border-color: var(--golden);
}

.round input[type='checkbox']:checked + label:after {
    opacity: 1;
}
.d-flex {
    width: 100%;
}

.spl-padding {
    padding-bottom: 1.2rem !important;
}

</style>
<style scoped>
.inner_pre .card .card-body p.question {
    padding: 2rem;
}
</style>
